
export default {
  inheritAttrs: false,
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cardProps () {
      return {
        image: this.image,
        imageSize: this.imageSize,
        subtitle: this.subtitle,
        title: this.title,
        titleSize: this.titleSize,
        to: this.to,
      }
    },
    image () {
      return this.publication?.hero
    },
    imageSize () {
      return this.$attrs['image-size'] || 'xs:374x234 sm:608x380 md:565x353 lg:400x250'
    },
    isImageFull () {
      return this.$attrs['image-full'] || this.$attrs['image-full'] === ''
    },
    isSmall () {
      return this.$attrs.small || this.$attrs.small === ''
    },
    subtitle () {
      return this.isSmall ? this.publication?.subtitle : null
    },
    title () {
      return this.publication?.title
    },
    titleSize () {
      return this.isSmall ? 'sm' : 'md'
    },
    to () {
      return this.$link.publication(this.publication)
    },
    topicIcon () {
      return this.publication?.topic?.icon
    },
    topicName () {
      return this.publication?.topic?.name
    },
  },
}
